<style scoped lang="scss">
.teacher-title {
  font-size: 18px;
  font-weight: 600;
  color: #474747;
  position: relative;
  line-height: 18px;
  text-indent: 15px;
}

.teacher-title::after {
  content: "";
  width: 5px;
  height: 18px;
  background-color: #6340c8;
  border-radius: 2.5px;
  position: absolute;
  top: 0;
  left: 0;
}

.teacher-desc {
  display: flex;
  margin: 40px 15px;

  .teacher-desc-left {
    width: 310px;
  }

  .teacher-desc-right {
    flex: 1;
  }
}

.teacher-desc-list {
  display: flex;
  line-height: 30px;
  font-size: 14px;
  color: #595959;

  .title {
    width: 80px;
  }

  .value {
    font-size: 14px;
    color: #6340c8;
  }
}

// 授课情况
.teaching-situation {
  padding: 24px 15px;
}

.teaching-card {
  width: 100%;
  display: flex;
  height: 16.56vw;

  .teaching-card-list:first-child {
    margin-left: 0;
  }

  .teaching-card-list {
    width: 33%;
    margin-left: 18px;
    background-color: #f6f7fc;
    border-radius: 10px;
  }
}

::v-deep {
  .el-input--medium .el-input__inner {
    height: 50px;
    width: 250px;
    line-height: 50px;
    background-color: #f6f7fc;
    border-radius: 10px;
    border: none;
  }

  .el-form-item--medium .el-form-item__label {
    line-height: 50px;
  }
}
</style>
<template>
  <section class="teacher">
    <div class="teacher-title">基本信息</div>

    <div class="teacher-desc">
      <div class="teacher-desc-left">
        <div class="teacher-desc-list">
          <div class="title">教师姓名:</div>
          <div class="value">{{ teacherInfo.teuse_name }}</div>
        </div>

        <div class="teacher-desc-list">
          <div class="title">省/市/区:</div>
          <div class="value">{{ teacherInfo.teuse_province_city_area }}</div>
        </div>

        <div class="teacher-desc-list">
          <div class="title">教师身份:</div>
          <div class="value">{{ teacherInfo.identityName }}</div>
        </div>
      </div>
      <div class="teacher-desc-right">
        <div class="teacher-desc-list">
          <div class="title">账号:</div>
          <div class="value">{{ teacherInfo.teuse_account }}</div>
        </div>
        <!-- <div class="teacher-desc-list">
          <div class="title">地区:</div>
          <div class="value">{{teacherInfo.teuse_province_city_area}}</div>
        </div> -->
        <div class="teacher-desc-list">
          <div class="title">教师评级:</div>
          <div class="value">{{ teacherInfo.teuse_lev_title }}</div>
        </div>
        <div class="teacher-desc-list">
          <div class="title">授课班级:</div>
          <div>
            <div class="value" v-for="(item, index) in teacherInfo.identityClass" :key="index">{{ item.className }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="teacher-title">授课情况</div>
    <div class="teaching-situation">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="授课班级">
          <el-select v-model="formInline.sccla_id" placeholder="请选择" @change="init()">
            <el-option v-for="item in class_option" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="teaching-card">
        <div class="teaching-card-list" ref="preparation_rate"></div>
        <div class="teaching-card-list" ref="integrity_rate"></div>
        <div class="teaching-card-list" ref="pass_rate"></div>
      </div>
    </div>
  </section>
</template>
<script>
import { teauserInfo } from "@api/teacher";
import { getTeaClass } from "@api/SchSchool";
export default {
  name: "",
  data() {
    return {
      formInline: {
      },
      teacherInfo: {},
      option: {
        title: [
          {
            text: 70 + "%",
            x: "center",
            y: "center",
            textStyle: {
              fontWeight: "600",
              color: "#6340C8",
              fontSize: "26",
            },
          },
          {
            text: "备课率",
            x: "center",

            bottom: "5%",
            textStyle: {
              fontSize: "18",
              color: "#474747",
              fontWeight: "normal",
            },
          },
        ],
        tooltip: {
          formatter: function (params) {
            return params.name + "：" + params.percent + " %";
          },
        },
        legend: {
          show: false,
          itemGap: 12,
          data: ["占比", "剩余"],
        },

        series: [
          {
            name: "circle",
            type: "pie",
            clockWise: true,
            radius: ["38%", "60%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: 0,
                name: "占比",
                itemStyle: {
                  normal: {
                    color: "", // 0% 处的颜色
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: "剩余",
                value: 0,
                itemStyle: {
                  normal: {
                    color: "",
                  },
                },
              },
            ],
          },
        ],
      },
      class_option: [],
    };
  },
  async created() {
    this.init();
    await this.getClassSelete()
    
  },
  methods: {
    /**初始化 */
    async init() {
      const res = await teauserInfo({ id: this.$route.params.id, sccla_id: this.formInline.sccla_id });
      if (res.status == 200) {
        this.teacherInfo = res.data;
        this.drawpreparation_rate();
        this.drawintegrity_rate();
        this.drawpass_rate();
      }
    },
    /**获取班级下拉 */
    async getClassSelete() {
      const res = await getTeaClass(this.$route.params.id);
      if (res.status == 200) {
        this.class_option = res.data;
        this.formInline.sccla_id = res.data[0].key;
      }
    },
    drawpreparation_rate() {
      let myChart = this.$echarts.init(this.$refs.preparation_rate);
      let value = this.teacherInfo.teachingRate;
      let color1 = "#6340C8";
      let color2 = "#DCD2F8";
      let option = JSON.parse(JSON.stringify(this.option));
      option.series[0].data[0].value = value;
      option.series[0].data[1].value = 100 - value;
      option.title[0].textStyle.color = color1;
      option.title[0].text = value + "%";
      option.title[1].text = "备课率";
      option.series[0].data[0].itemStyle.normal.color = color1;
      option.series[0].data[1].itemStyle.normal.color = color2;
      myChart.setOption(option);
    },
    drawintegrity_rate() {
      let myChart = this.$echarts.init(this.$refs.integrity_rate);
      let value = this.teacherInfo.teachingAllRate;
      let color1 = "#FCB332";
      let color2 = "#F8E1B9";
      let option = JSON.parse(JSON.stringify(this.option));
      option.series[0].data[0].value = value;
      option.series[0].data[1].value = 100 - value;
      option.title[0].textStyle.color = color1;
      option.title[0].text = value + "%";
      option.title[1].text = "备课完整率";
      option.series[0].data[0].itemStyle.normal.color = color1;
      option.series[0].data[1].itemStyle.normal.color = color2;
      myChart.setOption(option);
    },
    drawpass_rate() {
      let myChart = this.$echarts.init(this.$refs.pass_rate);
      let value = this.teacherInfo.examPassRate;
      let color1 = "#1743E0";
      let color2 = "#DCE2FB";
      let option = JSON.parse(JSON.stringify(this.option));
      option.series[0].data[0].value = value;
      option.series[0].data[1].value = 100 - value;
      option.title[0].textStyle.color = color1;
      option.title[0].text = value + "%";
      option.title[1].text = "考试合格率";
      option.series[0].data[0].itemStyle.normal.color = color1;
      option.series[0].data[1].itemStyle.normal.color = color2;
      myChart.setOption(option);
    },
  },

};
</script>
